import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { PlusCircleIcon } from "lucide-react";
import { Checkbox } from "../ui/checkbox";
import type { Lang } from "@/types";
import { useTranslations } from "@/i18n";
import { useMediaQuery } from "@/hooks/use-media-query";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../ui/drawer";
import { useState, type ReactNode } from "react";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { cn } from "@/lib/utils";
import { screens } from "screens";

export type Choice = {
  label: string;
  value: string;
  renderLabel?: ReactNode;
  keywords?: string[];
  disabled?: boolean;
};

type Props = {
  title: string;
  choices: Choice[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  lang: Lang;
  size?: "default" | "sm";
  className?: string;
  noSearch?: boolean;
  embedded?: boolean;
};

export const SelectMultiple = ({
  title,
  choices,
  selectedValues,
  setSelectedValues,
  lang,
  size = "default",
  className,
  noSearch,
  embedded,
}: Props) => {
  const t = useTranslations(lang);
  const isMobile = useMediaQuery(`(max-width: ${screens.md})`);
  const [open, setOpen] = useState(false);
  const [q, setQ] = useState("");

  const content = (
    <Command>
      {!noSearch && (
        <CommandInput
          value={q}
          onValueChange={setQ}
          placeholder={t("common.search") + "..."}
        />
      )}
      <CommandList className={cn({ "max-h-none": embedded })}>
        {choices.length === 0 && (
          <CommandEmpty>{t("common.noResults")}</CommandEmpty>
        )}
        {!embedded && selectedValues.length > 0 && (
          <>
            <CommandGroup>
              <CommandItem
                onSelect={() => setSelectedValues([])}
                className="justify-center text-center"
              >
                {t("common.clear")} {title}
              </CommandItem>
            </CommandGroup>
            <CommandSeparator />
          </>
        )}
        <CommandGroup
          className={cn({
            "max-h-[160px] xl:max-h-[224px] overflow-auto p-0 mt-2": embedded,
          })}
        >
          {choices.map((choice) => {
            const isSelected = selectedValues.includes(choice.value);
            return (
              <CommandItem
                key={choice.value}
                onSelect={() => {
                  if (isSelected) {
                    setSelectedValues(
                      selectedValues.filter((v) => v !== choice.value),
                    );
                  } else {
                    setSelectedValues([...selectedValues, choice.value]);
                  }
                  if (embedded) {
                    setQ("");
                  }
                }}
                className={cn("flex gap-2", {
                  "data-[selected=true]:bg-transparent hover:data-[selected=true]:bg-accent":
                    embedded,
                })}
              >
                <Checkbox checked={isSelected} />
                <div className="flex-1">
                  {choice.renderLabel || choice.label}
                </div>
              </CommandItem>
            );
          })}
        </CommandGroup>
        {embedded && selectedValues.length > 0 && (
          <>
            <CommandSeparator />
            <CommandGroup>
              <CommandItem
                onSelect={() => setSelectedValues([])}
                className="justify-center text-center"
              >
                {t("common.clear")} {title}
              </CommandItem>
            </CommandGroup>
          </>
        )}
      </CommandList>
    </Command>
  );

  if (embedded) {
    return content;
  }

  const trigger = (
    <Button
      size={size}
      variant="outline"
      className={cn("flex gap-1 justify-between", className)}
    >
      <div className="flex gap-1 items-center">
        <span>{title}</span>
        {selectedValues.length > 0 && (
          <Badge variant="secondary" className="font-normal px-1">
            {selectedValues.length}
          </Badge>
        )}
      </div>
      <PlusCircleIcon className="h-4 w-4" />
    </Button>
  );

  if (!isMobile) {
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>{trigger}</PopoverTrigger>
        <PopoverContent className="w-[200px] p-0" align="start">
          {content}
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent>
        <VisuallyHidden>
          <DrawerHeader>
            <DrawerTitle>{t("common.select")}</DrawerTitle>
            <DrawerDescription>{t("common.select")}</DrawerDescription>
          </DrawerHeader>
        </VisuallyHidden>
        <div className="mt-4 border-t">{content}</div>
      </DrawerContent>
    </Drawer>
  );
};

import type { Supplier } from "@/services/supplier";
import type { Lang } from "@/types";
import { createContext, useContext } from "react";
import { type ProductCardProps } from "./ProductCards";
import { type Choice } from "@/components/controls/SelectMultiple";
import type { ProductsQueryParams } from "../types";

export type ProductsUIData = {
  lang: Lang;
  supplier: Supplier;
  brandChoices: Choice[];
  categoryChoices: Choice[];
  stockChoices: Choice[];
  offerChoices: Choice[];
  sortChoices: Choice[];
  selectedBrands: string[];
  setSelectedBrands: (v: string[]) => void;
  selectedCategories: string[];
  setSelectedCategories: (v: string[]) => void;
  selectedStock: ProductsQueryParams["stock"];
  setSelectedStock: (v: ProductsQueryParams["stock"]) => void;
  selectedOffer: ProductsQueryParams["offer"];
  setSelectedOffer: (v: ProductsQueryParams["offer"]) => void;
  selectedSort: ProductsQueryParams["sort"];
  setSelectedSort: (v: ProductsQueryParams["sort"]) => void;
  productCards: ProductCardProps[];
  filtersMode: "sidebar" | "topbar";
  viewMode: "list" | "grid";
  setViewMode: (v: "list" | "grid") => void;
  initialLoading: boolean;
  showLoadMore: boolean;
  loadingMore: boolean;
  loadMore: () => void;
  query: ProductsQueryParams;
};

export const ProductsUIContext = createContext<ProductsUIData | undefined>(
  undefined,
);

export const useProductsUIData = () => {
  const data = useContext(ProductsUIContext);
  if (data === undefined) {
    throw new Error("useProductsUIData must be used within a ProductsUI");
  }
  return data;
};
